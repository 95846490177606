import React from "react";
import Helmet from "react-helmet";
import { useTranslation } from "react-i18next";
import "twin.macro";

import EquipmentOwnerSvg from "../../static/images/equipment-owners.svg";
import { Layout } from "../components/Layout/Layout";
import TheBigTrio from "../components/TheBigTrio";
import { getTranslationList } from "../utils/common";

const EquipmentOwner: React.FC = () => {
  const { t } = useTranslation();

  const list = getTranslationList(t, "equipmentOwners.list");

  return (
    <Layout>
      <Helmet title={t("equipmentOwners.header")} />
      <TheBigTrio header="equipmentOwners.header" subheader="equipmentOwners.subheader" icon={<EquipmentOwnerSvg tw="" />} list={list} />
    </Layout>
  );
};

export default EquipmentOwner;
